import { Colors } from 'commons/Theme'
import { StyledBackdrop, StyledLoaderModal } from './LoaderModal.style'

export const LoaderModal = ({
  showModal,
  fullScreen = true,
  showBackdrop = true,
}: {
  showModal: boolean
  fullScreen?: boolean
  showBackdrop?: boolean
}) => {
  if (!showModal) {
    return null
  }

  return (
    <>
      <StyledLoaderModal $fullScreen={fullScreen}>
        <svg
          width="65"
          height="64"
          viewBox="0 0 65 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M64.5 32C64.5 49.6731 50.1731 64 32.5 64C14.8269 64 0.5 49.6731 0.5 32C0.5 14.3269 14.8269 0 32.5 0C50.1731 0 64.5 14.3269 64.5 32ZM8.5 32C8.5 45.2548 19.2452 56 32.5 56C45.7548 56 56.5 45.2548 56.5 32C56.5 18.7452 45.7548 8 32.5 8C19.2452 8 8.5 18.7452 8.5 32Z"
            fill={Colors.blue200}
          />
          <path
            d="M32.5 4C32.5 1.79086 34.2977 -0.0257546 36.4897 0.249676C40.1532 0.710038 43.7198 1.80235 47.0277 3.48779C51.5272 5.78041 55.4203 9.10539 58.3885 13.1909C61.3568 17.2764 63.316 22.0063 64.106 26.9941C64.6868 30.6609 64.6235 34.3905 63.9292 38.017C63.5138 40.1867 61.2306 41.3351 59.1296 40.6525C57.0286 39.9698 55.9148 37.7121 56.2394 35.527C56.5971 33.1198 56.5876 30.6645 56.2045 28.2456C55.612 24.5048 54.1426 20.9573 51.9164 17.8932C49.6902 14.829 46.7704 12.3353 43.3958 10.6158C41.2136 9.50399 38.8814 8.7363 36.4816 8.33257C34.303 7.96607 32.5 6.20914 32.5 4Z"
            fill={Colors.azure600}
          />
        </svg>
        {fullScreen && <div>Ci siamo quasi</div>}
      </StyledLoaderModal>

      {showBackdrop && fullScreen !== true && (
        <StyledBackdrop $show={showModal} />
      )}
    </>
  )
}
