import styled from 'styled-components'
import { ContainerFluid } from '../commons/Container'
import { BPSizes, Colors, ColumnsGap, Rem } from '../commons/Theme'

export const StyledDeleteContainer = styled(ContainerFluid)`
  padding-top: 80px;
  padding-bottom: 80px;

  @media screen and (min-width: ${BPSizes.desktop}px) {
    padding-top: 160px;
    padding-bottom: 160px;
  }
`

export const StyledDeleteLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${ColumnsGap.sm};
  grid-column: 1 / span 4;

  @media screen and (min-width: ${BPSizes.md}px) {
    grid-column: 1 / span 6;
  }

  @media screen and (min-width: ${BPSizes.desktop}px) {
    grid-column: 2 / span 4;
  }
`

export const StyledDeleteRightContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  grid-column: 1 / span 4;

  @media screen and (min-width: ${BPSizes.md}px) {
    column-gap: ${ColumnsGap.md};
    row-gap: 24px;
    grid-column: 1 / span 6;
  }

  @media screen and (min-width: ${BPSizes.lg}px) {
    grid-column: 6 / span 6;
  }
`

export const StyledDeleteTitle = styled.div`
  color: ${Colors.azure600};
  font-weight: 600;
  line-height: 120%;
  font-size: ${Rem(28)};

  @media screen and (min-width: ${BPSizes.desktop}px) {
    font-size: ${Rem(40)};
  }
`

export const StyledDeleteForm = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  column-gap: ${ColumnsGap.sm};
  row-gap: 20px;
  grid-template-columns: 1fr;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: ${BPSizes.md}px) {
    column-gap: ${ColumnsGap.md};
    row-gap: 32px;
  }
`

// Thank you page

export const StyledTYP = styled.div`
  background: ${Colors.azure600};
  padding-top: 80px;
  padding-bottom: 80px;

  @media screen and (min-width: ${BPSizes.desktop}px) {
    padding-top: 160px;
    padding-bottom: 160px;
  }
`

export const StyledTYPContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`

export const StyledTYPTextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 16px;
`
export const SendIconWrapper = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* @media screen and (min-width: ${BPSizes.desktop}px) {
    width: 48px;
    height: 48px;
  } */
`
