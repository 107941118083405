import Layout from 'Layout'
import { Colors, StyledP } from 'commons/Theme'
import {
  eventDataLayer,
  getGA4PageSezione,
  isValidEmail,
  prepareStringForDataLayer,
  removeTrailingSlashes,
} from 'commons/utils'
import { Icon } from 'components/Icons/Icon'
import { LoaderModal } from 'components/Modale/LoaderModal/LoaderModal'
import {
  StyledDeleteContainer,
  StyledDeleteForm,
  StyledDeleteLeftContainer,
  StyledDeleteRightContainer,
  StyledDeleteTitle,
  StyledTYP,
  StyledTYPContent,
  StyledTYPTextContent,
} from 'pages-style/cancellazione-dati.style'
import { useCallback, useState } from 'react'
import { AtomButton } from '../atoms/AtomButton'
import { AtomLink } from '../atoms/AtomLink'
import { Input } from '../commons/FormComponents/Input'
import { TYPWarningIcon } from '../components/Icons/TYPWarningIcon'
import { SendIcon } from '../components/Icons/SendIcon'
import { useRecaptcha } from 'hooks/useRecaptcha'
import { BootstrapCols } from '../commons/BootstrapCols'
import { TrashIcon } from 'components/Icons/TrashIcon'

const TYP = ({
  showTY,
  onRetry,
}: {
  showTY: 'ok' | 'ko'
  onRetry: () => void
}) => {
  return (
    <StyledTYP>
      <BootstrapCols cols={12} tabletCols={8} largeCols={6}>
        <StyledTYPContent>
          <Icon
            stroke="white"
            width="64px"
            tabletWidth="80px"
            desktopWidth="120px"
            Icon={showTY === 'ko' ? TYPWarningIcon : SendIcon}
          />
          <StyledTYPTextContent>
            <StyledDeleteTitle style={{ fontWeight: '500', color: 'white' }}>
              {showTY === 'ok' && 'Richiesta inviata'}
              {showTY === 'ko' && 'Ooops'}
            </StyledDeleteTitle>
            <StyledP $color="white" style={{ textAlign: 'center' }}>
              {showTY === 'ok' &&
                'A breve riceverai una e-mail per completare la richiesta di cancellazione.'}
              {showTY === 'ko' &&
                "L'operazione si è interrotta a causa di un problema tecnico temporaneo. Verifica che il tuo dispositivo sia correttamente connesso a internet e riprova."}
            </StyledP>
          </StyledTYPTextContent>
          {showTY === 'ok' && <AtomLink label="Vai al sito" to="/" noIcon />}

          {showTY !== 'ok' && <AtomButton label="Riprova" onClick={onRetry} />}
        </StyledTYPContent>
      </BootstrapCols>
    </StyledTYP>
  )
}

const DeleteData = () => {
  const [email, setEmail] = useState<string>('')
  const [emailMatch, setEmailMatch] = useState<string>('')
  const [showTY, setShowTY] = useState<'ok' | 'ko' | undefined>()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const { verifyRecaptcha } = useRecaptcha()

  const clearError = (error: string) => {
    setErrors((d) => {
      delete d[error]
      return { ...d }
    })
  }

  const resetAllErrors = useCallback(() => {
    setErrors({})
  }, [])

  function validateForm() {
    resetAllErrors()

    const errorsForm: Record<string, string> = {}

    if (email.length === 0) {
      Object.assign(errorsForm, { email: 'Campo obbligatorio' })
    }

    if (email.length > 0 && !isValidEmail(email)) {
      Object.assign(errorsForm, { email: 'Email errata' })
    }

    if (emailMatch.length === 0) {
      Object.assign(errorsForm, { emailMatch: 'Campo obbligatorio' })
    }

    if (emailMatch.length > 0 && !isValidEmail(emailMatch)) {
      Object.assign(errorsForm, { emailMatch: 'Email errata' })
    }

    if (email.length > 0 && emailMatch.length > 0 && email !== emailMatch) {
      Object.assign(errorsForm, {
        emailMatch: 'Le due email non corrispondono',
      })
    }

    setErrors(errorsForm)

    const isFormValid = Object.entries(errorsForm).length === 0

    return isFormValid
  }

  function handleOnSubmit() {
    const isValidForm = validateForm()
    if (!isValidForm) {
      return
    }
    setShowLoader(true)
    verifyRecaptcha({
      onSuccess: () => {
        fetch(
          `${removeTrailingSlashes(
            process.env.A2A_DRUPAL_CASA_URL
          )}/a2a-api/energy-solutions/richiesta-cancellazione-contatto`,
          {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
            },
            body: JSON.stringify({
              email: email,
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            if (res.status) {
              // DataLayer Form submitted - success response
              eventDataLayer({
                event: 'richiesta_cancellazione_dati_personali',
                stream: 'web',
                label: prepareStringForDataLayer(window.location.href),
                position: prepareStringForDataLayer(window.location.href),
                sezione: getGA4PageSezione(),
              })
              setShowTY('ok')
            } else {
              // DataLayer Form submitted - error response
              eventDataLayer({
                event: 'custom_error',
                stream: 'web',
                label: 'call_back_contact',
                sezione: getGA4PageSezione(),
                error_type: `richiesta_cancellazione_dati_personali-${
                  res.message ?? ''
                }`,
              })
              setShowTY('ko')
            }
          })
          .catch((err) => {
            // DataLayer Form submitted - error response
            eventDataLayer({
              event: 'custom_error',
              stream: 'web',
              label: 'call_back_contact',
              sezione: getGA4PageSezione(),
              error_type: `richiesta_cancellazione_dati_personali`,
            })
            setShowTY('ko')
            console.warn(err, 'error in fetch')
          })
          .finally(() => setShowLoader(false))
      },
      onError: () => {
        setShowLoader(false)
        setShowTY('ko')
      },
    })
  }

  return (
    <Layout pageTitle={'Cancellazione dati'}>
      {showTY ? (
        <TYP showTY={showTY} onRetry={() => setShowTY(undefined)} />
      ) : (
        <StyledDeleteContainer>
          <StyledDeleteLeftContainer>
            <Icon width="32px" stroke={Colors.azure600} Icon={TrashIcon} />
            <StyledDeleteTitle>
              Cancellazione dei dati personali
            </StyledDeleteTitle>
          </StyledDeleteLeftContainer>
          <StyledDeleteRightContainer>
            <StyledP>
              Per chiedere la cancellazione dei tuoi dati personali, inserisci
              l&lsquo;indirizzo e-mail che hai utilizzato per inviarci una
              richiesta di contatto commerciale:
            </StyledP>
            <StyledDeleteForm>
              <Input
                id="email"
                onChange={(val) => {
                  clearError('email')
                  setEmail(val)
                }}
                value={email}
                label="E-mail"
                placeholder="Email"
                type="email"
                required
                // onFocus={() => {
                //     setEmailError('')
                // }}
                // validate={() => {
                //   setEmailError(
                //     !email
                //       ? 'Campo obbligatorio'
                //       : !isValidEmail(email)
                //         ? 'Email errata'
                //         : ''
                //   )
                //   email === emailMatch && setEmailMatchError('')
                // }}
                error={errors.email}
                validateOnBlur
              />
              <Input
                id="emailMatch"
                onChange={(val) => {
                  clearError('emailMatch')
                  setEmailMatch(val)
                }}
                value={emailMatch}
                label="Conferma E-mail"
                placeholder="Conferma la tua email"
                type="email"
                required
                // onFocus={() => {
                //   setEmailMatchError('')
                // }}
                // validate={() => {
                //   setEmailMatchError(
                //     !emailMatch
                //       ? 'Campo obbligatorio'
                //       : !isValidEmail(emailMatch)
                //         ? 'Email errata'
                //         : email !== emailMatch
                //           ? 'Le due email non corrispondono'
                //           : ''
                //   )
                // }}
                validateOnBlur
                error={errors.emailMatch}
              />
              <AtomButton
                label="Invia"
                onClick={handleOnSubmit}
                icon={<SendIcon />}
                iconPosition="right"
                disabled={showLoader}
                onDataLayer={() => {
                  return
                }}
              />
            </StyledDeleteForm>
          </StyledDeleteRightContainer>
          <LoaderModal showModal={showLoader} fullScreen={false} />
        </StyledDeleteContainer>
      )}
    </Layout>
  )
}

export default DeleteData
