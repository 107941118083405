export const TYPWarningIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 121 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M113.644 85.0007L75.4437 18.4207C69.0037 7.1957 52.7487 7.1957 46.3087 18.4207L8.11369 85.0007C1.7237 96.1407 9.79869 110.001 22.6787 110.001H99.0787C111.959 110.001 120.034 96.1407 113.644 85.0007Z"
        strokeWidth="3.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.8789 70V40"
        strokeWidth="3.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.8789 90V90.05"
        strokeWidth="3.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
