import { Colors, Palette, SiteSpacings } from 'commons/Theme'
import styled, { css, keyframes } from 'styled-components'
import { zIndexModal } from '../Modal.style'

export const zIndexLoaderModal = zIndexModal + 100

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`

export const StyledLoaderModal = styled.div<{
  $fullScreen?: boolean
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: ${zIndexLoaderModal};

  ${({ $fullScreen }) =>
    !$fullScreen &&
    css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 42px;
      width: 155px;
      height: 155px;
      border-radius: ${SiteSpacings.Spacing04.md}px;
      border: 1px solid ${Palette.Greyscale[500]};
    `};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  font-family:
    'Life Sans',
    Arial,
    system-ui,
    -apple-system,
    'Segoe UI',
    'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  color: ${Colors.azure600};

  svg {
    animation: ${spin} 1s linear infinite;

    ${({ $fullScreen }) =>
      !$fullScreen &&
      css`
        width: 64px;
        height: 64px;
      `};
  }
`

export const StyledLoaderIcon = styled.div`
  svg {
    animation: ${spin} 1s linear infinite;
    width: 64px;
    height: 64px;
  }
`

export const StyledBackdrop = styled.div<{ $show?: boolean }>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: ${zIndexLoaderModal - 1};
  opacity: 1;

  ${({ $show }) =>
    $show &&
    css`
      display: block;
    `};
`
